<template>
    <div>
        <div>
            <h2 class="mb-2">Remittance Reports</h2>
            <b-row style="background: white;" class="mb-0">
                <!-- Search -->
                <b-col cols="12" md="3">
                    <b-form-group>
                        <h5>Close on</h5>
                        <flat-pickr v-model="rangeDateCloseOn" class="form-control" :config="{ mode: 'range' }" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                    <b-form-group>
                        <h5>Payment date</h5>
                        <flat-pickr v-model="rangeDatePaymentDate" class="form-control" :config="{ mode: 'range' }" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                    <h5>Driver</h5>
                    <v-select v-model="driverSeletedFilter" :options="optionsDrivers" label="title" />
                </b-col>


            </b-row>

            <b-row>
                <b-col cols="12" class="mb-2">
                    <b-button @click="openCreateInvoiceRemittanceDriver()" class="btn btn-success mr-1 mt-1"
                        style="left: right;" size='sm'>
                        Create Invoice
                    </b-button>

                    <b-button @click="SendEmailRemittance(selectedInvoiceRemittanceDriver)" class="btn mr-1 mt-1"
                        style="float: right;background: #7367f0 !important" size='sm'>
                        Email report
                    </b-button>
                    <b-button @click="GetPdfRemittanceBinary(selectedInvoiceRemittanceDriver)"
                        class="btn btn-warning mr-1 mt-1" style="float: right;" size='sm'>
                        Download report
                    </b-button>
                    <b-button class="btn btn-success mr-1 mt-1" style="float: right;" @click="clearFilter()" size='sm'>
                        Clear Filter
                    </b-button>
                    <b-button class="btn btn-primary mr-1 mt-1" style="float: right;"
                        @click="getListInvoiceRemittanceDriver('')" size='sm'>
                        Filter data
                    </b-button>
                </b-col>
            </b-row>



            <div style="overflow-x: scroll; min-height: 500px">
                <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
                    <thead head-variant="dark">
                        <tr>
                            <th>Invoice id</th>
                            <th style="min-width: 140px;">Close on</th>
                            <th>Payment day</th>
                            <th>Driver</th>
                            <th>Driver payment</th>
                            <th>Is paid to driver</th>
                            <th>Tickets number</th>
                            <th>Adjustment amount</th>
                            <th>Adjustment description</th>
                            <th>Created by</th>
                            <th style=" min-width: 280px !important;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click="setSelectedInvoiceRemittanceDriver(item)" v-for="item in listItems" :key="item.id">
                            <td>{{ item.id }} </td>
                            <!-- <td> {{ getDate(item.invoice_clients[0].pickup_date) }}</td> -->
                            <td>{{ convertDate(item.close_on_start)}} - {{ convertDate(item.close_on_end) }}</td>
                            <td>{{ convertDate(item.payment_date) }}</td>
                            <td>{{ getDriverName(item) }}</td>
                            <!-- <td> {{ getDriverName(item.invoice_clients[0].driver) }}</td> -->
                            <td> {{ getPaymentDriver(item) }}</td>
                            <td> {{ (item.is_paid == 1 || item.is_paid == "1") ? "Yes" :
                                "No" }}
                            </td>
                            <td>
                                {{ getTicketNumber(item) }}
                            </td>
                            <td>
                                {{ item.adjustment_amount }}
                            </td>
                            <td>
                                {{ item.adjustment_description }}
                            </td>
                            <td>
                                        {{ getCreatedBy(item) }}
                                    </td>
                            <td>
                                <b-button @click="GetInvoiceRemittanceDriverById(item.id)" class="btn btn-warning"
                                    size='sm'>
                                    Edit
                                </b-button>
                                <b-button @click="DeleteInvoiceRemittance(item.id)" class="btn btn-danger ml-2"
                                    size='sm'>
                                    Delete
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td colspan="11" variant="secondary" class="text-right">
                                Total Rows: <b>{{ totalItems }}</b>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>



            <div class="pagination_block">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.first_page_url)">&laquo;</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.prev_page_url)">Previous</button>
                        </li>
                        <li class="page-item" v-if="current_page - 2 > 0">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page - 2))">{{ current_page
                                    - 2
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.prev_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">{{
                                current_page - 1
                            }}</button>
                        </li>
                        <li class="page-item">
                            <button type="button" class="page-link"
                                :class="{ current: pagination.current_page == current_page }">{{ current_page
                                }}</button>
                        </li>
                        <li class="page-item" v-if="pagination.next_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">{{
                                current_page + 1
                            }}</button>
                        </li>
                        <li class="page-item" v-if="current_page + 2 <= last_page">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.path + '?page=' + (current_page + 2))">{{ current_page
                                    + 2
                                }}</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.next_page_url)">Next</button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link"
                                @click="getItems(pagination.last_page_url)">&raquo;</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <b-modal id="modal-edit-invoice" centered hide-footer>
            <div class="d-block text-center">
                <b-col>
                    <b-row>
                        <h3> Ticket number {{ selectedInvoice.booking_id }}</h3>
                    </b-row>
                    <b-row class="mt-2">
                        <b-form-radio v-model="selectedInvoice.is_paid" name="radios-automatic-price" value="1">It's
                            paid to driver
                        </b-form-radio>
                        <b-form-radio class="ml-2" v-model="selectedInvoice.is_paid" name="radios-automatic-price"
                            value="0">It's not
                            paid to driver</b-form-radio>
                    </b-row>
                    <b-row style="float:right" class="mt-2 mb-2">
                        <b-button size="sm" style="background: #7367f0 !important"
                            @click="updateIsPaidDriver(selectedInvoice.id, selectedInvoice.is_paid_driver)">
                            Save
                        </b-button>
                    </b-row>
                </b-col>
            </div>
        </b-modal>



        <b-modal no-close-on-backdrop id="modal-update-invoice-remitance" centered hide-footer size="lg">
            <h3>{{ currentAcciont == 'add' ? 'Add invoice' : 'Edit invoice' }}</h3>
            <br>
            <b-row>
                <b-col cols="12" md="5">
                    <b-form-group label="Close on - start">
                        <b-form-datepicker v-model="InvoiceRemittanceDriver.close_on_start" locale="en"
                            placeholder="0/00/0000" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="5">
                    <b-form-group label="Close on - end">
                        <b-form-datepicker v-model="InvoiceRemittanceDriver.close_on_end" locale="en"
                            placeholder="0/00/0000" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="5">
                    <b-form-group label="Payment date">
                        <b-form-datepicker v-model="InvoiceRemittanceDriver.payment_date" locale="en"
                            placeholder="0/00/0000" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="3">
                    <b-form-radio v-model="InvoiceRemittanceDriver.is_paid" name="radios-automatic-price" value="1">It's
                        paid
                    </b-form-radio>

                </b-col>
                <b-col cols="12" md="3">

                    <b-form-radio v-model="InvoiceRemittanceDriver.is_paid" class="ml-2" name="radios-automatic-price"
                        value="0">It's not
                        paid</b-form-radio>
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="12" md="12">
                    <span v-if="InvoiceRemittanceDriver.id == null">Select the Driverx</span>
                    <span v-if="InvoiceRemittanceDriver.id != null">Driver</span>
                    <v-select @close="clearListTicketsRemittance()" :disabled="InvoiceRemittanceDriver.id != null"
                        v-model="driverSeletedForm" :options="optionsDrivers" label="title"
                        placeholder="Please select some item" />
                </b-col>
            </b-row>

            <b-row class="mt-1" v-if="this.isEditForm() == true">
                <b-col cols="4" md="3">
                    <span>Adjustment amount</span>
                    <b-form-input v-model="InvoiceRemittanceDriver.adjustment_amount" size="sm" md="2" />
                </b-col>
                <b-col cols="8" md="9">
                    <span>Adjustment Description</span>
                    <b-form-input v-model="InvoiceRemittanceDriver.adjustment_description" size="sm" md="2" />
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col cols="12" md="12">
                    <b-button @click="saveInvoiceDriver()" class="btn btn-success mr-1" style="float: right;" size='sm'>
                        Save Invoice
                    </b-button>
                    <b-button @click="SetUpaidAdditionalTicketDriverFromInvoices(driverSeletedForm)" size="sm"
                        class="btn-warning mr-2" style="float: right;">
                        Add tickets
                    </b-button>
                </b-col>

            </b-row>


            <b-row class="mt-2">
                <b-col cols="12" md="12">

                    <div v-if="listTicketsRemittance.length == 0"
                        style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
                        <h3>There is no tickets</h3>
                        <br>
                    </div>


                    <div id="" style="overflow:scroll; height:350px;" v-if="listTicketsRemittance.length > 0">
                        <span>List of tickects</span>
                        <b-table-simple hover small caption-top responsive class="mt-1">
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th>Service date</b-th>
                                    <b-th>Customer</b-th>
                                    <b-th>Confirmation</b-th>
                                    <b-th>Amount</b-th>
                                    <b-th></b-th>
                                </b-tr>
                            </b-thead>
                            <tbody>
                                <tr v-for="item in listTicketsRemittance" :key="item.id">
                                    <td>{{ item.pickup_date }} </td>
                                    <td>{{ getSelfpayName(item) }} </td>
                                    <td>{{ item.booking_id }}</td>
                                    <td> {{ item.po_rate }}</td>
                                    <td>
                                        <template v-if="InvoiceRemittanceDriver.id == null">
                                            <b-button
                                                @click="DeleteInvoiceRemittanceDetailByInvoiceClientId(item.id, currentAcciont)"
                                                class="btn btn-danger" style="left: right;" size='sm'>
                                                Delete
                                            </b-button>
                                        </template>
                                        <template v-if="InvoiceRemittanceDriver.id != null">
                                            <b-button
                                                @click="DeleteInvoiceRemittanceDetailByInvoiceClientId(item.id, currentAcciont)"
                                                class="btn btn-danger" style="left: right;" size='sm'>
                                                Delete
                                            </b-button>
                                        </template>


                                    </td>
                                </tr>
                            </tbody>
                        </b-table-simple>


                    </div>
                </b-col>
            </b-row>



        </b-modal>

        <b-modal id="modal-select-additional-tickets" centered hide-footer size="lg">
            <b-row>
                <b-col>
                    <h3>
                        Add tickets
                    </h3>
                </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="12" md="5">
                    <b-form-group>
                        <h5>Service date</h5>
                        <flat-pickr v-model="rangeServiceDate" class="form-control" :config="{ mode: 'range' }" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                    <b-button @click="updateInformacionUpaidAdditionalTicketDriverFromInvoices(currentDriverId)"
                        size="sm" class="btn-success mr-2" style="float: left;margin-top: 30px;">
                        Filter
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="12">
                    <b-button v-if="listAdditinalTicketFromInvoices.length > 0 && isEditForm() == true"
                        @click="AddListInvoiceRemittanceDetail(currentAcciont)" size="sm" class="btn-warning mr-2"
                        style="float: right;">
                        Add Tickets
                    </b-button>
                    <b-button v-if="listAdditinalTicketFromInvoices.length > 0 & isEditForm() == false"
                        @click="AddListInvoiceRemittanceDetail(currentAcciont)" size="sm" class="btn-warning mr-2"
                        style="float: right;">
                        Add Tickets
                    </b-button>
                    <b-button v-if="listAdditinalTicketFromInvoices.length > 0" @click="cancelAddAdditionalTickets()"
                        class="btn btn-danger mr-2" style="float: right;" size='sm'>
                        Cancel
                    </b-button>
                </b-col>
            </b-row>
            <b-row v-if="listAdditinalTicketFromInvoices.length > 0">
                <b-col>
                    <h5>Select the tickets you want to add</h5>
                </b-col>
            </b-row>
            <div v-if="listAdditinalTicketFromInvoices.length > 0" id="" style="overflow:scroll; height:350px;">
                <b-col cols="12" md="12">
                    <b-row>
                        <!-- Table to add addittional tickets when edit -->
                        <b-table-simple hover small caption-top responsive class="mt-1">
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th>Service date</b-th>
                                    <b-th>Customer</b-th>
                                    <b-th>Confirmation</b-th>
                                    <b-th>Amount</b-th>
                                    <b-th></b-th>
                                </b-tr>
                            </b-thead>
                            <tbody>
                                <tr v-for="item in listAdditinalTicketFromInvoices" :key="item.id">
                                    <td>{{ item.pickup_date }} </td>
                                    <td>{{ getSelfpayName(item) }} </td>
                                    <td>{{ item.booking_id }}</td>
                                    <td> {{ item.po_rate }}</td>
                                    <td>
                                        <input type="checkbox" class="ticketSeleted" :value="item.id">
                                    </td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </b-row>
                </b-col>
            </div>
            <div v-if="listAdditinalTicketFromInvoices.length == 0"
                style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
                <h3>There is no tickets to add</h3>
                <br>
                <b-button @click="cancelAddAdditionalTickets()" class="btn btn-danger mr-2" size='sm'>
                    Close
                </b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem,
    BFormSelect, BTableSimple, BThead, BTr, BTd, BTh, BTbody, BTfoot, BFormGroup, BFormRadio,
    BFormDatepicker, BFormCheckbox,BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { getMessageError } from '@core/utils/utils'
import { convertToMMDDYYYY, convertToHHMM, convertToMMDDYYYYHHmm } from '@core/utils/dates'
// import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from '@core/components/infoClients/UserListAddNew'
import { selectedRow } from "@core/utils/cssStyle";



export default {
    name: 'ListReservationToCancel',
    components: {
        UserListAddNew,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        BTableSimple,
        BThead, BTr, BTd, BTh, BTbody, BTfoot,
        BFormGroup,
        flatPickr,
        vSelect,
        BFormRadio,
        BFormDatepicker,
        BFormCheckbox,
        BFormTextarea
    },
    watch: {
        currentUrl() {
            sessionStorage.setItem("currentUrl", JSON.stringify(this.currentUrl));
        }
    },
    data() {
        return {
            listItems: [],
            perPage: 10,
            currentPage: 1,
            totalItems: 0,
            pageOptions: [3, 5, 10],
            pagination: {}, current_page: 1, last_page: 0,
            currentUrl: "",
            rangeDateCloseOn: null,
            rangeDatePaymentDate: null,
            rangeCompletedTicked: null,
            rangeServiceDate: null,
            selectedInvoice: {},
            listDrivers: [],
            optionsDrivers: [],
            driverSeletedFilter: null,
            driverSeletedForm: null,
            listTicketsRemittance: [],
            listAdditinalTicketFromInvoices: [],
            InvoiceRemittanceDriver: {
                id: null,
                close_on_start: null,
                close_on_end: null,
                payment_date: null,
                amount: null,
                is_paid: 1,
                driver_id: null,
            },
            selectedInvoiceRemittanceDriver: null,
            currentDriverId: null,
            currentAcciont: null
        }
    },
    methods: {
        async GetPdfRemittanceBinary(item) {
            let id = null;
            if (item == null) {
                return false;
            }
            else {
                id = item.id;
            }
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`/admin/panel/get_pdf_remittance_binary`, {
                    params: {
                        id: id,
                    },
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/pdf",
                    }
                })
                .then((response) => {
                    var blob = new window.Blob([response.data], {
                        type: "application/pdf",
                    });
                    var url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    var win = window.open();
                    win.document.write(
                        '<iframe src="' +
                        url +
                        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                    );
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async SetUpdaidTicketDriverFromInvoices(driverId) {
            this.listTicketsRemittance = [];
            this.listTicketsRemittance = await this.GetInvoicesNotInInvoiceRemittanceDriver(driverId);
        },
        async SetUpaidAdditionalTicketDriverFromInvoices(driverSeletedForm) {
            let driverId = null;
            if (driverSeletedForm != null) {
                driverId = driverSeletedForm.value;
            }

            if (driverId == null) {
                this.$swal({
                    title: "Please select a driver",
                });
                return false;
            }
            this.clearFilterRangeDatesServiceDate();
            this.currentDriverId = driverId;
            this.updateInformacionUpaidAdditionalTicketDriverFromInvoices(driverId);
            // this.$bvModal.hide("modal-update-invoice-remitance");
        },
        async updateInformacionUpaidAdditionalTicketDriverFromInvoices(driverId) {
            this.listAdditinalTicketFromInvoices = await this.GetInvoicesNotInInvoiceRemittanceDriver(driverId);
            this.listTicketsRemittance.forEach(element => {
                this.listAdditinalTicketFromInvoices = this.listAdditinalTicketFromInvoices.filter(x => x.id != element.id);
            });
            this.$bvModal.show("modal-select-additional-tickets");
        },
        async GetInvoicesNotInInvoiceRemittanceDriver(driverId) {
            let res = null;
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let data = this.getValuesParametersTicketsCompleteNoAdded();

            res = await this.$http
                .get(`/admin/panel/get_invoices_not_in_invoice_remittance_driver`,
                    {
                        params: {
                            driver_id: driverId,
                            dateStart: data.dateStart,
                            dateEnd: data.dateEnd,
                        },
                    },
                )
                .then((response) => {
                    this.$swal.close();
                    return response.data.data;
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
            return res;
        },
        async GetInvoiceClientInformation(bookingId) {

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`/admin/panel/invoice/get_invoice_by_booking_id/${bookingId}`,
                )
                .then((response) => {

                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async SendEmailRemittance(item) {

            let id = null;
            if (item == null) {
                return false;
            }
            else {
                id = item.id;
            }

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`/admin/panel/send_email_remittance`, {
                    params: {
                        id: id,
                    },
                })
                .then((response) => {

                    this.$swal({
                        title: response.data.data,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        getDrivers() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .get(`admin/panel/driver/list?basicInformation=1`)
                .then((response) => {
                    this.listDrivers = response.data.data;
                    this.loadMenuOptionsDrivers();
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal(
                        {
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                }
                );
        },
        loadMenuOptionsDrivers() {
            this.optionsDrivers = this.listDrivers.map(x => {
                let data = {
                    value: `${x.id}`,
                    title: `${x.name} ${x.lastname}`
                };
                return data;
            });
        },
        async updateIsPaidDriver(invoiceId, isPaidDriver) {
            this.$swal({
                title: "Please, wait updating fees...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append('idInvoice', invoiceId);
            formData.append('is_paid_driver', isPaidDriver)
            await this.$http
                .post(`admin/panel/update-is-paid-driver-invoice`, formData)
                .then((response) => {
                    this.$bvModal.hide("modal-edit-invoice");
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        convertDate(data) {
            return convertToMMDDYYYYHHmm(data)
        },
        convertTime(data) {
            return convertToHHMM(data)
        },
        getItems(url) {
            this.getListInvoiceRemittanceDriver(url)
        },
        getCorporateAccount(item) {
            let res = ''
            try {
                if (item.corporate_account) {
                    res = item.corporate_account.company_legal_name.toUpperCase()
                }
            }
            catch (err) {
            }

            return res
        },
        getListInvoiceRemittanceDriver(url) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let data = this.getValuesParameters();

            if (url.length === 0) {
                url = `admin/panel/get_list_invoice_remittance_driver`
            }

            this.currentUrl = url;

            this.$http
                .get(url, {
                    params: {
                        driver_id: data.driver_id,
                        dateStartCloseOn: data.dateStartCloseOn,
                        dateEndCloseOn: data.dateEndCloseOn,
                        dateStartPaymentDate: data.dateStartPaymentDate,
                        dateEndPaymentDate: data.dateEndPaymentDate
                    },
                }
                )
                .then((response) => {
                    this.listItems = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.current_page = response.data.data.current_page
                    this.last_page = response.data.data.last_page
                    this.pagination = response.data.data
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal(
                        {
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                }
                );
        },
        getValuesParametersTicketsCompleteNoAdded() {
            let data = {}
            let dateStart = 0
            let dateEnd = 0
            let driver_id = null;

            if (this.driverSeletedFilter != null) {
                driver_id = this.currentDriverId;
            }

            //Dates variables filter
            if (this.rangeServiceDate !== null) {
                dateStart = this.rangeServiceDate.split("to")[0]
                dateEnd = this.rangeServiceDate.split("to")[1]

                if (dateStart != "") {
                    dateEnd = dateEnd + " 23:59:59";
                    dateStart = dateStart + "00:00:00";
                }
            }

            data = {
                driver_id: driver_id,
                dateEnd: dateEnd,
                dateStart: dateStart,
            }

            return data
        },
        getValuesParameters() {
            let data = {}
            let dateStartCloseOn = 0
            let dateEndCloseOn = 0
            let dateStartPaymentDate = 0
            let dateEndPaymentDate = 0
            let driver_id = null;

            if (this.driverSeletedFilter != null) {
                driver_id = this.driverSeletedFilter.value;
            }

            //Dates variables filter
            if (this.rangeDateCloseOn !== null) {
                dateStartCloseOn = this.rangeDateCloseOn.split("to")[0]
                dateEndCloseOn = this.rangeDateCloseOn.split("to")[1]

                if (dateStartCloseOn != "") {
                    dateEndCloseOn = dateEndCloseOn + " 23:59:59";
                    dateStartCloseOn = dateStartCloseOn + "00:00:00";
                }
            }

            if (this.rangeDatePaymentDate !== null) {
                dateStartPaymentDate = this.rangeDatePaymentDate.split("to")[0]
                dateEndPaymentDate = this.rangeDatePaymentDate.split("to")[1]

                if (dateStartPaymentDate != "") {
                    dateEndPaymentDate = dateEndPaymentDate + " 23:59:59";
                    dateStartPaymentDate = dateStartPaymentDate + "00:00:00";
                }
            }



            data = {
                dateStartCloseOn: dateStartCloseOn,
                dateEndCloseOn: dateEndCloseOn,
                dateStartPaymentDate: dateStartPaymentDate,
                dateEndPaymentDate: dateEndPaymentDate,
                driver_id: driver_id,
            }

            return data
        },
        getDriverName(item) {
            let res = ""
            try {
                res = `${item.driver.name} ${item.driver.lastname}`
                let adsf = 0;
            }
            catch (err) {
                console.log(err)
            }
            return res
        },
        clearFilter() {
            this.rangeDatePaymentDate = null
            this.driverSeletedFilter = null
            this.rangeDateCloseOn = null
        },
        clearFilterRangeDatesServiceDate() {
            this.rangeServiceDate = null
        },
        getDate(item) {
            let res = "";
            try {
                res = this.convertDate(item)
            } catch (error) {

            }
            return res;
        },
        getPickUpBookingDay(item) {
            let res = "";
            try {
                const d = new Date(item.booking.garage_time);
                res = d.getDay();
            } catch (error) {

            }
            return res;
        },
        getPORate(item) {
            let res = "";

            try {
                res = item.booking.driver_price;
            } catch (error) {

            }
            return res;
        },
        getPOpayment(item) {
            let res = "";

            try {
                res = this.getPORate(item) + this.getAdditionalCharges(item);
            } catch (error) {

            }
            return res;

        },
        getItemsSort(column) {
            this.orderDesc = this.orderDesc == null ? true : !this.orderDesc;
            this.columnOrder = column;
            this.getInvoicesByFilter("");
        },
        getDriverName(item) {
            let res = "";
            try {
                res = item.driver.name + " " + item.driver.lastname;
            } catch (error) {

            }
            return res;
        },
        getSelfpayName(item) {
            let res = "";
            try {
                res = item.self_pay.name + " " + item.self_pay.lastname
            } catch (error) {

            }
            return res;
        },
        clearInfoInvoiceDriver() {
            this.InvoiceRemittanceDriver = {
                id: null,
                close_on: null,
                payment_date: null,
                amount: null,
                is_paid: 1,
                driver_id: null,
            };
            this.listTicketsRemittance = [];
            this.driverSeletedForm = null;
        },
        saveInvoiceDriver() {
            let listTicketSeleted = [];

            this.listTicketsRemittance.forEach(element => {
                listTicketSeleted.push(element.id);
            });

            this.InvoiceRemittanceDriver['listTickets'] = listTicketSeleted;
            this.InvoiceRemittanceDriver.driver_id = this.driverSeletedForm.value;


            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let url = ``;

            //if is new
            if (this.isEditForm() == false) {
                url = `admin/panel/add_invoice_remittance_driver`;
            } else {
                //if is edit
                url = `admin/panel/update_invoiceRemittance_driver`;
            }

            this.$http
                .post(url, this.InvoiceRemittanceDriver)
                .then((response) => {
                    this.$swal.close();
                    if (this.isEditForm() == false) {
                        this.$bvModal.hide("modal-update-invoice-remitance");
                        this.clearInfoInvoiceDriver();
                    }

                    this.getListInvoiceRemittanceDriver(this.currentUrl);
                })
                .catch((error) => {
                    this.$swal(
                        {
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                }
                );
        },
        async AddListInvoiceRemittanceDetail(action) {
            let checkBoxesTicketSelected = document.getElementsByClassName('ticketSeleted');
            let listTicketSeleted = [];
            checkBoxesTicketSelected.forEach(element => {

                if (element.checked == true) {
                    listTicketSeleted.push(element.value);
                }
            });

            if (listTicketSeleted.length == 0) {
                return false;
            }

            if (action == 'add') {
                listTicketSeleted.forEach(element => {
                    let item = this.listAdditinalTicketFromInvoices.find(x => x.id == Number(element));
                    this.listTicketsRemittance.push(item);
                    this.listAdditinalTicketFromInvoices = this.listAdditinalTicketFromInvoices.filter(x => x.id != Number(element));
                });
            }

            if (action == 'edit') {
                this.InvoiceRemittanceDriver['listTickets'] = listTicketSeleted;

                this.$swal({
                    title: "Please, wait...",
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                });

                let url = `admin/panel/add_list_invoice_remittance_detail`;

                await this.$http
                    .post(url, this.InvoiceRemittanceDriver)
                    .then((response) => {
                        this.$swal.close();
                        listTicketSeleted.forEach(element => {
                            let item = this.listAdditinalTicketFromInvoices.find(x => x.id == Number(element));
                            this.listTicketsRemittance.push(item);
                            this.getListInvoiceRemittanceDriver(this.currentUrl);

                            this.listAdditinalTicketFromInvoices = this.listAdditinalTicketFromInvoices.filter(x => x.id != Number(element));


                        });

                        this.$swal({
                            title: "Ticket added",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });

                        //this.$bvModal.show("modal-update-invoice-remitance");
                        //this.$bvModal.hide("modal-select-additional-tickets");
                    })
                    .catch((error) => {
                        this.$swal(
                            {
                                title: getMessageError(error),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                    }
                    );
            }

            if (this.listAdditinalTicketFromInvoices.length == 0) {
                this.$bvModal.hide("modal-select-additional-tickets");
            }

        },
        getPaymentDriver(item) {
            let res = 0;
            item.invoice_remittance_drive_detail.forEach(element => {
                try {
                    if ((element.invoice_client.po_rate == null || element.invoice_client.po_rate == "") == false) {
                        res = res + parseFloat(element.invoice_client.po_rate);
                    }
                    if ((item.adjustment_amount == null || item.adjustment_amount == "") == false) {
                        res = res + parseFloat(item.adjustment_amount);
                    }

                } catch (error) {

                }
            });
            return res;
        },
        GetInvoiceRemittanceDriverById(id) {
            this.currentAcciont = 'edit';;
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .post(`admin/panel/get_Invoice_remittance_driver_by_id?id=` + id)
                .then((response) => {
                    this.InvoiceRemittanceDriver = response.data.data;
                    this.setlistTicketsRemittance(this.InvoiceRemittanceDriver);
                    this.driverSeletedForm = this.optionsDrivers.find(x => x.value == this.InvoiceRemittanceDriver.driver_id);
                    this.$swal.close();
                    this.$bvModal.show("modal-update-invoice-remitance");
                })
                .catch((error) => {
                    this.$swal(
                        {
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                }
                );
        },
        DeleteInvoiceRemittance(id) {

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .post(`admin/panel/delete_invoice_remittance?id=` + id)
                .then((response) => {
                    this.getListInvoiceRemittanceDriver(this.currentUrl);
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal(
                        {
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                }
                );
        },
        DeleteInvoiceRemittanceDetailByInvoiceClientId(id, action) {

            if (action == 'add') {
                this.listTicketsRemittance = this.listTicketsRemittance.filter(x => x.id != id);
            }

            if (action == 'edit') {
                this.$swal({
                    title: "Please, wait...",
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                });

                this.$http
                    .post(`admin/panel/delete_invoice_remittance_detail_by_invoice_client_id?id=` + id)
                    .then((response) => {
                        this.listTicketsRemittance = this.listTicketsRemittance.filter(x => x.id != id);
                        this.getListInvoiceRemittanceDriver(this.currentUrl);
                        this.$swal.close();
                    })
                    .catch((error) => {
                        this.$swal(
                            {
                                title: getMessageError(error),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                    }
                    );
            }

        },

        setlistTicketsRemittance(item) {
            this.listTicketsRemittance = [];
            item.invoice_remittance_drive_detail.forEach(element => {
                this.listTicketsRemittance.push(element.invoice_client);
            });
        },
        getTicketNumber(item) {
            let res = "";
            item.invoice_remittance_drive_detail.forEach(element => {
                res = res + " " + element.invoice_client.booking_id
            });
            return res;
        },
        openCreateInvoiceRemittanceDriver() {
            this.currentAcciont = 'add';
            this.clearInfoInvoiceDriver();
            this.$bvModal.show("modal-update-invoice-remitance");
        },
        isEditForm() {
            let res = true;
            if (this.InvoiceRemittanceDriver.id == null) {
                res = false;
            }
            return res;
        },
        cancelAddAdditionalTickets() {
            this.$bvModal.show("modal-update-invoice-remitance");
            this.$bvModal.hide("modal-select-additional-tickets");
        },
        setSelectedInvoiceRemittanceDriver(item) {
            this.selectedInvoiceRemittanceDriver = item;
        },
        convertDate(data) {
            return convertToMMDDYYYY(data);
        },
        clearListTicketsRemittance() {
            this.listTicketsRemittance = [];
        },
        getCreatedBy(item) {
            let res = "";
            try {
                res = item.amera_user.name;
            } catch (error) {

            }
            return res;
        }


    },
    mounted() {
        this.getListInvoiceRemittanceDriver('');
        this.getDrivers();
    },
    updated() {
        selectedRow();
    },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.urlPagina {
    text-decoration: none;
    color: #7367f0;
}

.urlPagina:hover {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.list-group-item:hover {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important;
    color: #fff !important;
    cursor: pointer;
}

.urlPagina::before {
    background-color: currentColor !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
    box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
    background: #7367f0;
}

.current {
    background: #7367f0;
    color: white;
}


tr {
    cursor: pointer;
    padding: 0;
}

th {
    background: #4b4b4b !important;
    color: white !important;
    text-transform: uppercase;
}

td {
    padding: 0;
}

.table td,
.table th {
    padding: 3px;
    font-size: x-small;
    min-width: 120px;
    border: solid 1px;
    border-color: #303033;
    background: #fdfdfd;
    color: #303033;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>