<template>
    <div>
      <ListRemittanceReports></ListRemittanceReports>
    </div>
  </template>
  
  <script>
  import ListRemittanceReports from '@core/layouts/components/admin-components/ListRemittanceReports.vue'
  
  export default {
    components: {
        ListRemittanceReports,
    },
    data() {
      return {}
    },
  }
  </script>
  
  <style>
  
  </style>
  